export const environment = {
  name: 'staging',
  production: false,
  API_BASE_URL: 'https://dev.equalall.org/api/',
  DOMAIN_URL: 'https://dev.equalall.org',
  bugsnag: '03a913f6a9d88469cd0fc21e5f030643',
  clevertap_id: 'TEST-KKW-6ZK-4R7Z',
  // facebook_id: '287403324698248',
  facebook_id: '7933275206795221',
  facebook_pixel: '538031250815425',
  firebase: {
    apiKey: "AIzaSyBVfAWneUn4_gAtgSyd8OIYhki5zo8e2gQ",
    authDomain: "equalalldev.firebaseapp.com",
    projectId: "equalalldev",
    storageBucket: "equalalldev.appspot.com",
    messagingSenderId: "251744847705",
    appId: "1:251744847705:web:82d76752c4f6ee924bae48",
    measurementId: "G-BGN3J5E639"
  },
  gcaptcha: '6Lemuy0UAAAAAAJL2gRKu1BzpYtg5oI8QJCT89bI',
  // google_client_id: '1035507078055-v1hbq46oa8g9vpfnt7m7s9b6i3cul0pc.apps.googleusercontent.com', // ketto id
  google_client_id: '251744847705-4mc3tp2jg0q9ivo6his977id70if9prg.apps.googleusercontent.com',
  google_client_id_one_tap: '251744847705-4mc3tp2jg0q9ivo6his977id70if9prg.apps.googleusercontent.com',
  gtm_id: 'GTM-WDBN2F2F',
  gtm_ids: <any>{
    'equalall': 'GTM-WDBN2F2F'
  },
  invisible_captcha: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  microsoft_clarity: 'f5d5n1q3au',
  puhser_key: '5a179ea8cd27b83badfc',
  meilisearch: {
    host: 'https://meilisearchdev.ketto.org',
    key: 'c2c6a47901b031f1d6fb89e66a227858ea42ddb3053bb746e32a990cdec751f0',
    index: 'fundraiser_dev',
    disease: {
      index: 'dev_diseases',
      not_listed: 1820
    },
    hospital: {
      index: 'dev_hospitals',
      not_listed: 804783,
      not_admitted: 804784,
      no_hospitalisation_required: 804785
    }
  },
  truecaller: {
    partnerKey: 'wWJP67524561d2a794a8fb52141cdc4f70987',
  },
  health_first_campaign_id: 812634,
  health_first_ct: 'health-first-default-campaign',
  campaign_url: "/stories/test-campaign-equal-all-815730"
};
