import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LazyDirective } from './lazy/lazy.directive';

@Component({
  selector: 'app-component-loader',
  standalone: true,
  imports: [CommonModule, LazyDirective],
  templateUrl: './component-loader.component.html',
  styleUrls: ['./component-loader.component.scss']
})
export class ComponentLoader implements OnChanges {

  // @Input() load: boolean = false;
  @Input() selector: string = '';
  @Input() inputs: any;
  @Input() outputs: any;

  lazy_config: any;

  ngOnChanges(changes: SimpleChanges): void {
    // if ((!changes?.['load']?.currentValue) || (changes?.['load']?.currentValue === changes?.['load']?.previousValue) || (changes?.['load']?.currentValue?.toString() === changes?.['load']?.previousValue?.toString())) {
    //   return;
    // }
    this.loadLazyComponent();
  }

  loadLazyComponent() {
    // if (!this.load) {
    //   return;
    // }
    this.lazy_config = {
      component: this.getPath()
    }

  }

  getPath() {

    switch (true) {

      case this.selector === 'app-beneficiery-details':
        return () => import('../beneficiery-details/beneficiery-details.component');

      case this.selector === 'app-donations':
        return () => import('../donations/donations.component');

      case this.selector === 'app-faq':
        return () => import('../faq/faq.component');

      case this.selector === 'app-social-strip':
        return () => import('../social-strip/social-strip.component');

      case this.selector === 'app-signin-signup':
        return () => import('../signin-signup/signin-signup.component');

      case this.selector === 'testing':
        return () => import('../testing/testing.component');

      case this.selector === 'app-story-desc':
        return () => import('../story-desc/story-desc.component');

      case this.selector === 'app-story-docs':
        return () => import('../story-docs/story-docs.component');

      case this.selector === 'app-story-tabs':
        return () => import('../story-tabs/story-tabs.component');

      case this.selector === 'app-what-we-care':
        return () => import('../what-we-care/what-we-care.component');

      default:
        return;
    }

  }

}
